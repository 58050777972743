import { EditAttributes } from "design-system/types/types";
import { ReactNode } from "react";
import cn from "clsx";

export interface RichTextProps {
  useDropCap?: boolean;
  children?: ReactNode;
  editAttribute?: EditAttributes;
}

export function RichText({
  useDropCap: useDropCapRaw,
  children,
  editAttribute,
}: RichTextProps) {
  const useDropCap = useDropCapRaw === true ? true : undefined;
  const hideDropCap = useDropCapRaw === false ? true : undefined;
  return (
    <div
      className={cn(
        "hbs-global-align-center hbs-rich-text",
        useDropCap && "hbs-use-dropcap",
        hideDropCap && "hbs-hide-dropcap",
      )}
      {...editAttribute}
    >
      {children}
    </div>
  );
}

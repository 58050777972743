import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { RichTextEntry } from "frontend/contentful/schema/blocks";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { RichText } from "design-system/components/blocks/rich-text/rich-text";

interface RichTextOptions {
  headerAbove?: boolean;
}

export const ContentfulRichText = createContentfulComponent<
  RichTextEntry,
  RichTextOptions
>(({ entry, options, createEditAttributes }) => {
  return (
    <RichText
      useDropCap={entry.fields.useDropCap}
      editAttribute={createEditAttributes({ entry, fieldId: "text" })}
    >
      <RenderContentfulRichText
        document={entry.fields?.text}
        resetHeaders={options?.headerAbove ? "h3" : "h2"}
      />
    </RichText>
  );
});
